import React from 'react'

export const landing = [{
        "path": require("assets/images/newLanding/attributes.png"),
        "path2": require("assets/images/newLanding/attributes_mobile.png"),
        "title": (<></>)
        // "title": (<div className="title shadows"><h3>Es hora de dar</h3><h3><span className="parkwaylush yellow">el primer paso</span></h3></div>)
    },
    {
        "path": require("assets/images/newLanding/family.png"),
        "title": (<div className="title top shadows"><h3><span>¡Tenemos el</span></h3><h3><span className="parkwaylush yellow">espacio ideal </span><span className="move-top purple">para ti!</span></h3></div>)
    },
    {
        "path": require("assets/images/newLanding/family2.png"),
        "path2": require("assets/images/newLanding/girl.png"),
        "title": (<div className="title bottom shadows"><h3>¡Cada vez estás</h3><h3><span className="move-top">más cerca </span><span className="parkwaylush">de tu depa!</span></h3></div>)
    }
]

export const promo = [
    {
        "path": require("assets/images/promociones/Sente-Big-summer-sale-promo-desktop.png"),
        // "path2": require("assets/images/promociones/promo-sente.jpg"),
        "title": (<></>),
        // "title": (<div className="title shadows"><h3>Es hora de dar</h3><h3><span className="parkwaylush yellow">el primer paso</span></h3></div>)
    },
    {
        "path": require("assets/images/newLanding/family.png"),
        "title": (<div className="title top shadows"><h3><span>¡Tenemos el</span></h3><h3><span className="parkwaylush yellow">espacio ideal </span><span className="move-top purple">para ti!</span></h3></div>)
    },
    {
        "path": require("assets/images/newLanding/Sente_form_1_3dorms.png"),
        "path2": require("assets/images/newLanding/girl.png"),
        "title": (<div className="title bottom shadows"><h3>¡Cada vez estás</h3><h3><span className="move-top">más cerca </span><span className="parkwaylush">de tu depa!</span></h3></div>),
        backgroundPosition: "bottom"
    }
]