import React, { Component } from 'react'
import $ from "jquery"
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom'
import Menu from "./Menu"
import { BannerPromo } from './BannerPromo';

export class FrontendHeader extends Component {
  fnToggleMenu = () =>{
    $('.menu-toggle').toggleClass("on");
    $('.content-menu-movil').toggleClass("on")
    $('.bolita').toggleClass("open")
    $('.myMenuItems').toggleClass("open")
  }

  fnMenuClose = () =>{
    $('.menu-toggle').removeClass("on");
    $('.content-menu-movil').removeClass("on")
    $('.bolita').removeClass("open")
    $('.myMenuItems').removeClass("open")
  }

  render() {
    return (
      // class promo-show
      <div className="header h-auto w-100 bg-white ">
        {/* <BannerPromo/> */}
        <div className="content-header d-flex justify-content-between align-items-center m-auto">
          <MediaQuery query="(max-width: 1495px)">
            <div onClick={this.fnMenuClose} className="mySvgContentLogo" style={{backgroundImage:`url(${require("assets/svg/header/contentLogo2.svg")})`}}>
              <Link to="/">
                <div className="logo" style={{backgroundImage:`url(${require("assets/svg/sente.svg")})`}}></div>
              </Link>
            </div>
            <div className="bolita"></div>
            <div className="myMenuItems w-100 d-flex justify-content-center align-items-center">
              <Menu actionClick={this}/>
            </div>
            <div className="content-menu-movil">
              <div className="menu-toggle" onClick={this.fnToggleMenu}>
                <div className="one" style={{marginTop:'19px'}}></div>
                <div className="two"></div>
                <div className="three"></div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery query="(min-width: 1495px)">
            <div className="d-flex">
              <ul>
                <li>
                  <Link to="/">
                    <div className="logo" style={{backgroundImage:`url(${require("assets/svg/sente.svg")})`}}></div>
                  </Link>
                </li>
              </ul>

              <ul className="logo-socios">
                <li className="svg_socios">
                  <Link to="/respaldo">
                    <div title="Cosapi Inmobiliaria" style={{backgroundImage:`url(${require("assets/svg/cosapi.svg")})`}}></div>
                  </Link>
                </li>
                <li style={{ marginRight:'0' }}>
                  <Link to="/respaldo">
                    <div className="svg_lar" title="Grupo Lar" style={{backgroundImage:`url(${require("assets/svg/grupo-lar.svg")})`}}></div>
                  </Link>
                </li>
              </ul>
            </div>

            <Menu actionClick={this}/>
          </MediaQuery>
        </div>
      </div>
    )
  }
}
