import React, {Component} from 'react'
import codConsumidor from "assets/pdf/consumer_protection/Codigo de Proteccion al Consumidor SENTE.pdf"
import cuadroAcabados from "assets/pdf/consumer_protection/Cuadro de Acabados_SENTE.pdf"
import memDescriptiva from "assets/pdf/consumer_protection/Memoria Descriptiva_SENTE.pdf"
export class DataProtectionModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            myModal:''
        }
    }
    fnModal = () => {
        this.setState({ myModal:(this.state.myModal == '' ? 'active' : '') })
    }
    link = {
        padding: '10px',
        textDecoration: 'none',
        fontFamily: 'Medium',
        color: '#75777B'
    }
    render() {
        return (
            <div className={"myModal " + this.state.myModal}>
                <div className={"overlay "+this.props.class} onClick={() => this.fnModal()}/>
                <div className="contentModal" style={{display:'flex', flexDirection: 'column'}}>
                    <a href={codConsumidor} style={this.link} target="_blank">Codigo de Proteccion al Consumidor</a>
                    <a href={cuadroAcabados} style={this.link} target="_blank">Cuadro de Acabados</a>
                    <a href={memDescriptiva} style={this.link} target="_blank">Memoria Descriptiva</a>
                </div>
            </div>
        )
    }
}