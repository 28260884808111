import axios from 'axios';
import {REFERRERS_ENDPOINT} from "../../config/api/endpoints"
/**
 * @author Juan Lopez Tello
 * */
export class ReferrerService {
    retrieve() {
        return axios.get(REFERRERS_ENDPOINT).then(response => response.data);
    }
    create(referrer) {
        return axios.post(REFERRERS_ENDPOINT, referrer);
    }
}