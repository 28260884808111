import React, { Component } from 'react'

export class Covid19PopUp extends Component {
    constructor() {
        super()
        this.phones = [
            "947514667",
            "975170632",
            "965785113"
        ]
        this.phone = this.phones[Math.floor(Math.random() * this.phones.length)]
        this.link = `https://api.whatsapp.com/send?phone=51${this.phone}&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Nesta`
    }
    componentDidMount() {
        const popup = document.querySelector(".pandemic-popup")
        const close = popup.querySelector(".close")
        // const stay = popup.querySelector(".stay")
        close.onclick = () => popup.style.display = "none";
        // stay.onclick = () => close.click()

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = (event) => {
            if(event.target === popup) close.click()
        }


        if (window.location.pathname.includes("/gracias")) {
            close.click()
        }
    }
    render() {
        return (
            <div className="pandemic-popup">
                <div className="content">
                    <a href="javascript:;" class="close">×</a>
                    <a href="http://www.grupolar.pe/evento/?ref=sente">
                        <img src={require("assets/images/sente-popup.png")} usemap="#imagemap"/>
                    </a>
                    {/* <a className="wsp" href={this.link} target="_blank"/>
                    <a className="stay" href="javascript:;"/> */}
                </div>
            </div>
        )
    }
}