import React, { Component } from 'react'
import SVGPhoneCall from "assets/svg/footer/telefono.svg"
import SVGChat from "assets/svg/footer/mail.svg"
import SVGCursor from "assets/svg/footer/ubicacion.svg"
import SVGLar from "../../assets/images/footer/background-footer-gerpal.svg"
import SVGCosapi from "../../assets/images/footer/background-footer-cosapi.svg"
// import PDF
import PDFpoliticas from "assets/pdf/politicasde-privacidad-sente.pdf"
import PDFterminos from "assets/pdf/terminos-y-condiciones-sente.pdf"
import {DataProtectionModal} from "./DataProtectionModal"
import $ from "jquery"
import './footer.scss'

export class EndFooter extends Component {
    constructor(props){
        super(props);
    }
    fnOpenNewWindow = (url) =>{
        window.open(url)
    }
    render() {
    return (
        <div className="section fp-auto-height">
            <DataProtectionModal class="modalPoliticas" />
            <div className="end-footer d-flex align-items-center">
                <div className="d-flex h-100 justify-content-between content-principal">
                    <div className="box content-comunica d-flex justify-content-center align-items-center ">
                        <div style={{lineHeight:'1em', zIndex: '1'}} className="text-white d-flex comunicate">
                            <div className="CarmenSansBold">Comunícate </div>
                            <div className="CarmenSansLight">con nosotros</div>
                        </div>
                    </div>
                    <div className="box content-info">
                        <div className="">
                            <ul style={{height:'94px',display:'flex'}}>
                            <li className="CarmenSansBold">
                                <a href="tel:+017064261">
                                    <div className="icon" style={{WebkitMask:`url(${SVGPhoneCall})`}} /> (01) 706 4261
                                </a>
                            </li>
                            <li className="CarmenSansBold">
                                <a href="mailto:comercial@sente.pe" rel="noopener noreferrer">
                                    <div className="icon" style={{WebkitMask:`url(${SVGChat})`}} /> comercial@sente.pe
                                </a>
                            </li>
                            <li className="CarmenSansBold">
                                <a href="https://goo.gl/maps/L6ruEZTa3QpnpnZBA" rel="noopener noreferrer" target="_blank">
                                    <div className="icon" style={{WebkitMask:`url(${SVGCursor})`}} /> Jr. Centenario 175
                                </a>
                            </li>
                        </ul>
                        </div>
                        <div className="menu-indecopi">
                            <div className="border-center" style={{width:"5px"}} />
                            
                        </div>
                        
                    </div>
                </div>
                <div className="d-flex h-100 justify-content-center content-socios">
                    <div className="box content-cosapi">
                        <div className="logo cursor-pointer" onClick={() => this.fnOpenNewWindow("https://cosapiinmobiliaria.com.pe/")} style={{backgroundImage:`url(${SVGCosapi})`}}></div>
                        &nbsp;&nbsp;&nbsp;
                    </div>
                    <div className="box content-lar">
                        <div className="logo cursor-pointer" onClick={() => this.fnOpenNewWindow("http://www.grupolar.pe/")} style={{backgroundImage:`url(${SVGLar})`}}></div>
                    </div>
                </div>
            </div>
            <div className='footer-lar'>
                <div className='grupo-lar'>
                    <div class="external-links">
                        <div class="x-container">
                        <ul className='links'>
                            <li>
                            <a href="https://grupolar.pe/textos-legales/" target="_blank">Textos legales</a>
                            </li>
                            <li>
                            <a title="Términos y condiciones" target="_blank" href={PDFterminos}>Términos y condiciones</a>
                            </li>
                            <li>
                            <a href="https://transparencia.grupolar.pe/proteccion-al-consumidor" title="Código de protección al consumidor " target="_blank">Protección al consumidor</a>
                            </li>
                            <li>
                            <a href={PDFpoliticas} title="Políticas de privacidad de datos" target="_blank">Políticas de privacidad de datos</a>
                            </li>	
                        </ul>
                        <ul className='links-image'>
                            <li>
                                <a class="enlace-reclamo" href="https://libro.grupolar.pe/reclamaciones-sente" title=""  target="_blank">
                                    <img src="https://grupolar.pe/wp-content/uploads/2021/11/libro-reclamaciones.png"/>
                                </a>
                            </li>
                            <li>
                                <a class="adi" href="https://dci.pe/" title="" target="_blank">
                                    <img src="https://f.hubspotusercontent40.net/hubfs/6972147/ESCRITORIO/dci.svg"/>
                                </a>
                            </li>
                            <li>
                            <a class="dci" href="https://www.asei.com.pe/" title="" target="_blank">
                                <img src="https://f.hubspotusercontent40.net/hubfs/6972147/ESCRITORIO/asei.bc8f6d5c-1.svg"/>
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div class="verify-projects">
                        <div class="x-container fx-m-x">
                        <p>Verificar proyectos en <a href="https://enlinea.indecopi.gob.pe/reclamavirtual/" target="_blank" rel="noopener">Indecopi</a></p>
                        </div>
                    </div>
                    <div class="copyright">
                        <div class="x-container fx-m-x">
                        <p>Copyright © 2021 <a>Grupo Lar</a>. Todos los derechos reservados</p>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
    }
}
