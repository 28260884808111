import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Brochure from "assets/pdf/Sente-Brochure-general.pdf"
import MediaQuery from 'react-responsive';
import SVGBox from "assets/svg/header/regalo.svg"
import Active from "assets/temp/link_active.svg"

export default class Menu extends Component {
  render() {
    return (
        <ul className="nav-list" style={this.props.style}>
            {/* <li>
              <NavLink exact to="/" onClick={this.props.actionClick.fnToggleMenu}>
                Inicio
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/departamentos" onClick={this.props.actionClick.fnToggleMenu}>
                Departamentos
              </NavLink>
            </li>
            <li>
              <NavLink to="/areas-comunes" className="specialMenu" onClick={this.props.actionClick.fnToggleMenu}>
                Áreas comunes
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/promociones" onClick={this.props.actionClick.fnToggleMenu}>
                  <div className="mybox" style={{WebkitMask:`url(${SVGBox})`}}></div>
                  <div className="point-red"></div>&nbsp;
                  <span translate="no"translate="no" className="span_promotion">Promociones</span>

              </NavLink>
            </li> */}
            <li>
              <NavLink to="/recorrido-virtual" onClick={this.props.actionClick.fnToggleMenu}>
                Recorrido virtual
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/multimedia" onClick={this.props.actionClick.fnToggleMenu}>
                Multimedia
              </NavLink>
            </li> */}
            <MediaQuery query="(max-width: 913px)">
            <li>
              <NavLink to="/respaldo" onClick={this.props.actionClick.fnToggleMenu}>
                Respaldo
              </NavLink>
            </li>
            </MediaQuery>
            <li>
              <NavLink to="/avance-de-obra/" onClick={this.props.actionClick.fnToggleMenu}>
                Avance de obra
              </NavLink>
            </li>
            <li>
              <NavLink to="/referidos" onClick={this.props.actionClick.fnToggleMenu}>
                Referidos
              </NavLink>
            </li>
            <li className="mr-0">
              <a href={Brochure} target="_blank" rel="noopener noreferrer" onClick={this.props.actionClick.fnToggleMenu}>
                Brochure
              </a>
            </li>
            {/* <li style={{display:'none'}}>
              <a href="https://www.cosapiinmobiliaria.com.pe/servicio-cliente" target="blank" onClick={this.props.actionClick.fnToggleMenu}>
                Post venta
              </a>
            </li> */}
        </ul>
    )
  }
}
