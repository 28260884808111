import axios from 'axios';
import {LEADS_ENDPOINT} from "../../config/api/endpoints"
/**
 * @author Juan Lopez Tello
 * */
export class LeadService {
    retrieve() {
        return axios.get(LEADS_ENDPOINT).then(response => response.data);
    }
    create(lead) {
        return axios.post(LEADS_ENDPOINT, lead);
    }
}