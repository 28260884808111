import React, { Component } from 'react'

export default class InputCheckbox extends Component {
    constructor(props){
        super(props);
    }
    setValue = (event,name) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.props.setValueTxt(obj)
    }
    render() {
        return (
            <div className="col-md-12">
                <div 
                    className={"form-group "+this.props.validClassName+" "+(this.props.estado ? "validado" : this.props.startValid ? "invalidado" : "")} 
                    style={{width:'100%'}}
                >               
                    <input 
                        className={this.props.className} 
                        id={"checkbox"+this.props.name} 
                        type="checkbox" 
                        checked={this.props.estado} 
                        onChange={(e) => this.setValue(e,this.props.name)}
                        name={this.props.name} 
                        required="" 
                        style={{display: "none"}} 
                    />
                    <label 
                        className="cbx" 
                        htmlFor={"checkbox"+this.props.name} 
                        style={{width:'100%'}}
                    >
                        <span>
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1" strokeWidth="3"></polyline>
                            </svg>
                        </span>
                        <span translate="no" className="text-center text-gray text-subtitle check">He leído y aceto la&nbsp;<a 
                                href={this.props.pdf} 
                                target="_blank" 
                                style={{color: "#FF9E16"}}
                            >
                                política de datos personales
                            </a>
                        </span>
                    </label>
                    {this.props.validator.message(this.props.name, this.props.estado, this.props.styleValid)}
                </div>
            </div>
        )
    }
}
