import React, { Component } from 'react'
import TermsConditions from "assets/pdf/Terminos_y_condiciones_sente.pdf"
import SimpleReactValidator from "simple-react-validator"
import { LeadService } from 'aplication/domain/services';
import $ from "jquery"
import './contactRecorrido.scss'
export default class ContactRecorrido extends Component {
    constructor(props){
        super(props);
        this.leadService = new LeadService();
        this.validator = new SimpleReactValidator();
        this.handleCreate = this.handleCreate.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    state = {
        startValid: true,
        fname: "",
        lname: "",
        email: "",
        phone: "",
        accepted: false,
    }
    
    setStateFromInput = ({target}) =>{
        this.setState({ [target.name]:  target.checked || target.value });
        this.forceUpdate();
        console.log(this.state)
    }
    submitForm = (e) =>{
        e.preventDefault()
        this.validator.allValid() ? this.handleCreate() : this.setState({ startValid: false });
    }
    handleCreate(event){
        const submit_btn = document.querySelector('[type=submit]')
        submit_btn.setAttribute("disabled", true)
        submit_btn.innerText = 'Enviando'
        
        this.leadService.create({
            fname: this.state.fname,
            lname: this.state.lname,
            email: this.state.email,
            telephone: this.state.phone,
            observation: "QR - cerco de obra",
            url_query: localStorage.getItem('url_query') || "",
        }).then(response => {
            console.log(response)
            submit_btn.innerText = 'Enviado'
            // this.clearInputs();
            window.open('https://my.matterport.com/show/?m=NdYwTQqfaXV&play=1&lang=null', '_blank').focus()
        }).catch(({response}) => {
            console.log(response)
            submit_btn.innerText = 'Error!'
            setTimeout(() => {
                submit_btn.removeAttribute("disabled")
                submit_btn.innerText = 'Enviar'
            }, 2000);
        })
    }
    componentDidMount() {
        try{
            document.querySelector(".nav-list").style = "display:none!important" 
            document.querySelector(".content-header.d-flex").style = "display:flex!important;justify-content:center!important;align-items:center"; 
            document.querySelector(".menu-toggle").style = "display:none!important"; 
            document.querySelector(".button-send").style = "width:auto!important"; 

        }
        catch(err){
            console.log(err)
        }
    } 
    render() {
        return (
            <section className="section landing-audi form-recorrido">
                <div className="left-right">
                    <div className="form">
                        <div className="loading">
                            <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                        <div className="icon">
                            <img src={require("assets/images/recorridoVirtual/icon.svg")} className="img img-fluid" />
                        </div>
                        <div className="text text-title text-center text-white CarmenSansRegular">
                            Vive una experiencia
                        </div>
                        <div className="parkwaylush text-center title-form-welcome" style={{color:'#ff9e16', width:'25vw'}}>
                            <img src={require("assets/images/recorridoVirtual/text.svg")} className="img img-fluid" />
                        </div>
                        <div className="sub-title c-white container">
                            <p className="paragraph">
                                Recorre nuestro departamento piloto y conoce tu próximo hogar.
                            </p>
                        </div>
                        <form onSubmit={(e)=>this.submitForm(e)}>
                            <div className="row step step__1">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="fname" className={"form-control "+(this.validator.fieldValid('fname') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Nombre *" value={this.state.fname} onChange={this.setStateFromInput} />
                                         {this.validator.message('fname', this.state.fname, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="lname" className={"form-control "+(this.validator.fieldValid('lname') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Apellido *" value={this.state.lname} onChange={this.setStateFromInput} />
                                         {this.validator.message('lname', this.state.lname, 'required')}
                                    </div>
                                </div>
                                
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="tel" name="phone" className={"form-control "+(this.validator.fieldValid('phone') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Teléfono *" value={this.state.phone} onChange={this.setStateFromInput} />
                                         {this.validator.message('phone', this.state.phone, 'required|numeric|min:7|max:9')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="email" name="email" className={"form-control "+(this.validator.fieldValid('email') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Correo *" value={this.state.email} onChange={this.setStateFromInput} />
                                         {this.validator.message('email', this.state.email, 'required|email')}
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <div className="form-group">
                                        <button type="submit" className="form-control  button-send">Ver recorrido virtual</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
        )
    }
}
