import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import SimpleReactValidator from "simple-react-validator"
import { LeadService } from 'aplication/domain/services';
import $ from "jquery"
import InputCheckbox from 'components/others/InputCheckbox';
import TermsConditions from "assets/pdf/Terminos_y_condiciones_sente.pdf"
// const $ = window.$

export default class ContactCarousel extends Component {
    constructor(props){
        super(props);
        this.leadService = new LeadService();
        this.validator = new SimpleReactValidator();
        this.handleCreate = this.handleCreate.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    state = {
        startValid: true,
        fname: "",
        lname: "",
        email: "",
        phone: "",
        id_type: "",
        id_number: "",
        birth_date: "",
        rooms_ammount: "",
        move_time: "",
        accepted: false,
    }
    owl = {
        options: {
            items: 1,
            margin: 0,
            dots: true,
            loop: false,
            autoplay: false
        },
        events: {
            onInitialized() {
            }
        }
    }
    toStep(current, step) {

        if (current === 1 && step === 2) {
            if (!(this.validator.fieldValid('fname') &&
                    this.validator.fieldValid('lname') &&
                    this.validator.fieldValid('email') &&
                    this.validator.fieldValid('phone') &&
                    this.validator.fieldValid('id_type') &&
                    this.validator.fieldValid('id_number') &&
                    this.validator.fieldValid('birth_date'))) {
                this.setState({startValid: false})
                return
            }
        } else if (current === 2 && step === 3) {
            if (!this.validator.fieldValid('rooms_ammount')) {
                this.setState({startValid: false})
                return
            }
        } else if (current === 3 && step !== 2) {
            if (!(this.validator.fieldValid('move_time') && this.validator.fieldValid('accepted'))) {
                this.setState({startValid: false})
                return
            }
        }

        const form = document.querySelector(".form")
        form.classList.add("standBy")

        setTimeout(() => {
            const steps = [{
                bold: "¡Bienvenido!",
                light: "Nos gustaría saber más de ti"
            }, {
                bold: `Hola ${this.state.fname},`,
                light: "¿Qué tipo de depa estás buscando?"
            }, {
                bold: `Tu depa de ${this.state.rooms_ammount} te espera.`,
                light: "¿Cuándo piensas mudarte?"
            }]

            const slides = document.querySelectorAll(".owl-promos .item")
            if(this.state.rooms_ammount.includes(2)){
                slides[2].style.backgroundImage = `url(${this.props.slides[2].path2})`
                slides[2].querySelector(".title").classList.replace("bottom", "top")
            } else {
                slides[2].style.backgroundImage = `url(${this.props.slides[2].path})`
                slides[2].querySelector(".title").classList.replace("top", "bottom")
            }

            document.querySelector(".title.title__bold").innerText = steps[step - 1].bold
            document.querySelector(".title.title__light").innerText = steps[step - 1].light
            document.querySelector(`.step__${current}`).style.display = "none"
            document.querySelector(`.step__${step}`).style.display = "flex"
            form.classList.remove("standBy")
            this.slideTo(step - 1)
        }, 500);
    }
    slideTo(i) {
        $(".owl-promos").trigger('to.owl.carousel', i)
        $(".dots a").slice(0, i + 1).addClass("active")
        $(".dots a").slice(i + 1).removeClass("active")
    }
    setStateFromInput = ({target}) =>{
        this.setState({ [target.name]:  target.checked || target.value });
        this.forceUpdate();
        console.log(this.state)
    }
    submitForm = (e) =>{
        e.preventDefault()
        this.validator.allValid() && this.state.rooms_ammount && this.state.move_time ? this.handleCreate() : this.setState({ startValid: false });
    }
    handleCreate(event){
        const submit_btn = document.querySelector('[type=submit]')
        submit_btn.setAttribute("disabled", true)
        submit_btn.innerText = 'Enviando'
        
        this.leadService.create({
            fname: this.state.fname,
            lname: this.state.lname,
            email: this.state.email,
            telephone: this.state.phone,
            document_type_id: this.state.id_type,
            document: this.state.id_number,
            date_of_birth: this.state.birth_date,
            rooms_ammount: this.state.rooms_ammount,
            move_time: this.state.move_time,
            district: this.state.district,
            observation: `${this.state.rooms_ammount}, se muda en ${this.state.move_time}, distrito procedencia ${this.state.district}`,
            url_query: localStorage.getItem('url_query') || ""
        }).then(response => {
            console.log(response)
            submit_btn.innerText = 'Enviado'
            // this.clearInputs();
            window.location.href = response.data.seller_phone ? '/gracias/?p=landing&seller_phone='+ response.data.seller_phone : '/gracias/?p=landing';
        }).catch(({response}) => {
            console.log(response)
            submit_btn.innerText = 'Error!'
            setTimeout(() => {
                submit_btn.removeAttribute("disabled")
                submit_btn.innerText = 'Enviar'
            }, 2000);
        })
    }
    setRooms(rooms) {
        document.querySelector("[for=dorms2]").classList.remove("invalid")
        document.querySelector("[for=dorms3]").classList.remove("invalid")
        this.setState({rooms_ammount: rooms})
        console.log(this.state)
    }
    setMoveDate(date) {
        document.querySelector("[for=time1]").classList.remove("invalid")
        document.querySelector("[for=time2]").classList.remove("invalid")
        document.querySelector("[for=time3]").classList.remove("invalid")

        this.setState({move_time: date})
        console.log(this.state)
    }
    componentDidMount() {
        // const form = document.querySelector("form")
        // form.querySelectorAll("input, select").forEach(e => {
        //     e.value = this.state[e.name];
        //     // e.change = (e) => this.setStateFromInput(e)
        // })
        const slides = document.querySelectorAll(".owl-promos .item")
        if (window.innerWidth <= 800 && this.props.slides[0].path2) {
            slides[0].style.backgroundImage = `url(${this.props.slides[0].path2})`
            if (this.props.slides[0].backgroundPosition) {
                slides[0].style['backgroundPosition'] = this.props.slides[0].backgroundPosition
            }
        }
    } 
    render() {
        return (
            <section className="section landing-audi ">
                <div className="left-right">
                    <div className="form">
                        <div className="loading">
                            <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                        <h2 className="title title__bold">¡Bienvenido!</h2>
                        <h2 className="title title__light">Nos gustaría saber más de ti</h2>
                        <form onSubmit={(e)=>this.submitForm(e)}>
                            <div className="row step step__1">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="fname" className={"form-control "+(this.validator.fieldValid('fname') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Nombre *" value={this.state.fname} onChange={this.setStateFromInput} />
                                         {this.validator.message('fname', this.state.fname, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="lname" className={"form-control "+(this.validator.fieldValid('lname') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Apellido *" value={this.state.lname} onChange={this.setStateFromInput} />
                                         {this.validator.message('lname', this.state.lname, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <select name="id_type" className={"form-control "+(this.validator.fieldValid('id_type') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                            onChange={this.setStateFromInput}
                                        >
                                            <option selected disabled>Tipo de Documento *</option>
                                            <option value="1">DNI</option>
                                            <option value="2">CE</option>
                                            <option value="3">RUC</option>
                                            <option value="4">Pasaporte</option>
                                        </select>
                                        {this.validator.message('id_type', this.state.id_type, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="tel" name="id_number" className={"form-control "+(this.validator.fieldValid('id_number') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="N° de documento *" value={this.state.id_number} onChange={this.setStateFromInput} />
                                         {this.validator.message('id_number', this.state.id_number, "required|numeric|min:7")}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="tel" name="phone" className={"form-control "+(this.validator.fieldValid('phone') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Teléfono *" value={this.state.phone} onChange={this.setStateFromInput} />
                                         {this.validator.message('phone', this.state.phone, 'required|numeric|min:7|max:9')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="email" name="email" className={"form-control "+(this.validator.fieldValid('email') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Correo *" value={this.state.email} onChange={this.setStateFromInput} />
                                         {this.validator.message('email', this.state.email, 'required|email')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="district" className={"form-control "+(this.validator.fieldValid('district') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="¿En qué distrito vives? *" value={this.state.district} onChange={this.setStateFromInput} />
                                         {this.validator.message('district', this.state.district, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="birth_date"
                                         onClick={(e)=>{e.target.type="date"; e.target.click()}}
                                         onFocus={(e)=>{e.target.type="date";}}
                                        className={"form-control "+(this.validator.fieldValid('birth_date') ? "is-valid" : ((this.state.startValid == true) ? "": "is-invalid"))}
                                         placeholder="Fecha de Nacimiento *" onChange={(e) => {
                                            this.setStateFromInput(e);
                                            e.target.placeholder =""
                                        } }  />
                                        {this.validator.message('birth_date', this.state.birth_date, 'required')}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <button type="button" className="form-control" onClick={()=>this.toStep(1, 2)}>Continuar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row step step__2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="radio" id="dorms2" name="rooms_ammount"/>
                                        <label htmlFor="dorms2"
                                        className={"like-button "+(this.validator.fieldValid('rooms_ammount') ? "valid" : ((this.state.startValid == true) ? "": "invalid"))}
                                        onClick={()=>this.setRooms("2 dorms")}>2 dorms</label>
                                    </div>           
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="radio" id="dorms3" name="rooms_ammount"/>
                                        <label htmlFor="dorms3"
                                        className={"like-button "+(this.validator.fieldValid('rooms_ammount') ? "valid" : ((this.state.startValid == true) ? "": "invalid"))}
                                        onClick={()=>this.setRooms("3 dorms")}>3 dorms</label>
                                    </div>
                                </div>
                                {this.validator.message('rooms_ammount', this.state.rooms_ammount, 'required')}
                                <div className="col-6">
                                    <div className="form-group arrows">
                                        <div className="arrow arrow__left" onClick={()=>this.toStep(2, 1)}></div>
                                        <div className="arrow arrow__right" onClick={()=>this.toStep(2, 3)}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <button type="button" className="form-control right"  onClick={()=>this.toStep(2, 3)}>Continuar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row step step__3"> 
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="radio" id="time1" name="move_time"/>
                                        <label htmlFor="time1"
                                        className={"like-button "+(this.validator.fieldValid('move_time') ? "valid" : ((this.state.startValid == true) ? "": "invalid"))}
                                        onClick={()=>this.setMoveDate("Menos de 3 meses")}
                                        >Menos de 3 meses</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="radio" id="time2" name="move_time"/>
                                        <label htmlFor="time2"
                                        className={"like-button "+(this.validator.fieldValid('move_time') ? "valid" : ((this.state.startValid == true) ? "": "invalid"))}
                                        onClick={()=>this.setMoveDate("En 3 meses")}
                                        >En 3 meses</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="radio" id="time3" name="move_time"/>
                                        <label htmlFor="time3"
                                        className={"like-button "+(this.validator.fieldValid('move_time') ? "valid" : ((this.state.startValid == true) ? "": "invalid"))}
                                        onClick={()=>this.setMoveDate("Más de 3 meses")}
                                        >Más de 3 meses</label>
                                    </div>
                                </div>
                                {this.validator.message('move_time', this.state.move_time, 'required')}
                                <div className="col-md-12">
                                    <div className="form-group no-border validado" style={{width: "100%"}}>
                                        <input className="inp-cbx" id="checkboxtermsConditions" type="checkbox" name="accepted" style={{display: "none"}} onClick={this.setStateFromInput} />
                                        <label className="cbx"
                                            for="checkboxtermsConditions" style={{width: "100%"}}>
                                            <span className={this.validator.fieldValid('accepted') ? "invalid" : this.state.startValid == false ? "invalid": "valid"}>
                                                <svg width="12px" height="10px"
                                                    viewBox="0 0 12 10">
                                                    <polyline points="1.5 6 4.5 9 10.5 1" stroke-width="3"></polyline>
                                                </svg>
                                            </span>
                                            <span translate="no" className="text-center text-gray text-subtitle check">He leído y aceto la&nbsp;<a href={TermsConditions} target="_blank" style={{color: "rgb(255, 158, 22)"}}>política de datos personales</a></span></label></div>
                                    </div>
                                    {this.validator.message('accepted', this.state.accepted, 'accepted')}
                                <div className="col-6">
                                    <div className="form-group arrows">
                                        <div className="arrow arrow__left" onClick={()=>this.toStep(3, 2)}></div>
                                        <div className="arrow arrow__right" onClick={(e)=>this.submitForm(e)}></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <button type="submit" className="form-control right">Enviar</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="logos"></div>
                    </div>
                    <div className="image">
                        <OwlCarousel options={this.owl.options} events={this.owl.events} className="owl-promos owl-carousel owl-theme owl-loaded owl-drag">
                            {this.props.slides.map((slide, idx)=>
                                <div className="item" data-index={idx} style={{backgroundImage: `url(${slide.path})`}}>
                                    {slide.title}
                                </div>
                            )}
                        </OwlCarousel>
                        <div className="dots">
                            <ul>
                                {this.props.slides.map((s, i) => 
                                    <li>
                                        <a href="javascript:;" onClick={()=>this.slideTo(i)} class={i === 0 && "active"}>
                                            <span></span><span>{i+1}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
