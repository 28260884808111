import {
    lazy
} from "react"
export const Inicio = lazy(() => import( /* webpackChunkName: "inicio" */ "./Inicio"))
export const Departamentos = lazy(() => import( /* webpackChunkName: "departamentos" */ "./Departamentos"))
export const Multimedia = lazy(() => import( /* webpackChunkName: "multimedia" */ "./Multimedia"))
export const Promociones = lazy(() => import( /* webpackChunkName: "promociones" */ "./Promociones"))
export const AvanceObra = lazy(() => import( /* webpackChunkName: "avancedeobra" */ "./AvanceObra"))
export const Respaldo = lazy(() => import( /* webpackChunkName: "respaldo" */ "./Respaldo"))
export const Referidos = lazy(() => import( /* webpackChunkName: "referidos" */ "./Referidos"))
export const DefiPromo = lazy(() => import( /* webpackChunkName: "defipromo" */ "./DefiPromo"))
export const Promotion = lazy(() => import( /* webpackChunkName: "Promotion" */ "./Promotion"))
export const Landing = lazy(() => import( /* webpackChunkName: "landing" */ "./Landing"))
export const Newlanding = lazy(() => import( /* webpackChunkName: "newlanding" */ "./Newlanding"))
export const LandingAudi = lazy(() => import( /* webpackChunkName: "audi" */ "./LandingF2"))
export const NewGracias = lazy(() => import( /* webpackChunkName: "newgracias" */ "./NewGracias"))
export const RecorridoVirtual = lazy(() => import( /* webpackChunkName: "recorridovirtual" */ "./RecorridoVirtual"))
export const AreasComunes = lazy(() => import( /* webpackChunkName: "areascomunes" */ "./AreasComunes"))
export const ComplaintsBook = lazy(() => import( /* webpackChunkName: "ComplaintsBook" */ "./ComplaintsBook"))