export const promocionesData = [
    // {
    //     'title': 'Celebremos juntos',
    //     'slug': 'Celebremos juntos',
    //     'timeInicio': '2022-05-09T00:00:00',
    //     'timeFinal': '2022-05-31T23:00:00',
    //     'img': require('assets/images/promociones/Sente-slider-promo.png'),
    //     'imageDefi': require("assets/images/promociones/Sente-slider-promo.png"),
    //     'image_responsiveDefi': require("assets/images/promociones/Sente-slider-promo.png"),
    // },
    {
        'title': 'Descuentos exclusivos',
        'slug': 'descuentos-exclusivos',
        'timeInicio': '2022-06-01T00:00:00',
        'timeFinal': '2022-06-30T23:00:00',
        'img': require('assets/images/promociones/descuentos-exclusivos.png'),
        'imageDefi': require("assets/images/promociones/descuentos-exclusivos.png"),
        'image_responsiveDefi': require("assets/images/promociones/descuentos-exclusivos.png"),
    },
    // {
    //     'title': 'Descuento',
    //     'slug': 'Solo-por-diciembre-llevate-un-descuento',   
    //     'timeInicio': '2021-12-01T00:00:00',
    //     'timeFinal': '2021-12-31T23:00:00',
    //     'img': require('assets/images/promociones/dic-2021/sente-diciembre-promo.png'),
    //     'imageDefi': require("assets/images/promociones/dic-2021/sente-diciembre-promo.png"),
    //     'image_responsiveDefi': require("assets/images/promociones/dic-2021/sente-diciembre-promo-movil.png"),
    // },
]