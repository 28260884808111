import React, { Component } from 'react'
import WebLayout from "layout/WebLayout"
import { promocionesData } from "Data/promociones"
import ContactCarousel from './ContactCarousel';
import {promo} from "Data/ContactSlides"

export default class Promotion extends Component {
    constructor(props){
        super(props);
        this.state = { promocionActiva: null }
    }

    fnValidPromotion = (promotion) => {
        return promotion.slug === this.props.match.params.id;
    }

    componentDidMount = () => {
        window.ga('send', 'pageview', window.location.pathname);
        let promocionActiva = promocionesData.find(this.fnValidPromotion);
        console.log(promocionActiva)
        promo[0].path = promocionActiva.imageDefi
        if (window.innerWidth <= 850) {
            promo[0].path = promocionActiva.image_responsiveDefi
        }
        // promo[0].path2 = promocionActiva.imageDefi
        this.setState({ promocionActiva: promo })
    }

    render() {
        
        return (
            <WebLayout className="pageDefiPromo" title="Sente | Promociones">
                {this.state.promocionActiva && <ContactCarousel slides={this.state.promocionActiva} />}
            </WebLayout>
        )
    }
}
