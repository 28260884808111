import React, {Component} from 'react'

export class BannerPromo extends Component {
    hide() {
        const header = document.querySelector(".header")
        const contactSlider = document.querySelector("section.landing-audi")
        if (header) {
            header.classList.remove("promo-show")
        } else {
            document.querySelector(".banner-promo").style.display="none";
            document.querySelector(".header-landing").style.top = "0";
        }
        if (window.location.pathname.includes("/promocion-nuevo")) {
            if (window.innerWidth <= 800) {
                contactSlider.style.marginTop = 0
                return
            }
            document.querySelector("section.landing-audi").style.marginTop = "82px";
        }
    }
    render() {
        return(
            <div className={`banner-promo ${this.props.display ? "display" : ""}`}>
                <div className="emotive-phrase">
                    <div className="arrows">
                        <span className="arrow primera"></span>
                        <span className="arrow segunda"></span>
                        <span className="arrow tercera"></span>
                    </div>
                    <div className="text">Hacemos una pausa pero&nbsp;<span className="coolText">Tus sueños continúan</span></div>
                </div>
                <div className="border"></div>
                <div className="offer">Recibe una Gift card de hasta <span>s/3,000</span></div>
                <a href="#contacto" className="button">Regístrate</a>
                <a className="cls" onClick={()=>this.hide()}>X</a>
            </div>
        )
    }
}