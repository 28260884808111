import React, {Component, Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {
    Inicio,
    Departamentos,
    Multimedia, 
    Promociones, 
    AvanceObra, 
    Respaldo, 
    Referidos, 
    DefiPromo, 
    Landing,
    // Newlanding,
    ComplaintsBook,
    LandingAudi,
    NewGracias,
    RecorridoVirtual,
    AreasComunes
} from "views"
import {FrontendHeader, EndFooter} from "components/Layout";
import ScrollToTop from "components/others/ScrollToTop"
import Loading from "components/others/Loading"
import { Covid19PopUp } from 'components/others/Covid19PopUp';
// import { BannerPromo } from 'components/Layout';
import Promotion from 'views/Promotion';
import ContactCarousel from 'views/ContactCarousel';
import ContactRecorrido from 'components/contactRecorrido/contactRecorrido';

export default class Routes extends Component {
    constructTime = new Date();
    render() {
        return (
            <Router>
                {/* <Covid19PopUp/> */}
                <ScrollToTop>               
                        <Suspense fallback={<Loading />}>
                            <Switch>
                                {/* <Route path="/landing-f2">
                                    <div className="page-newlanding">
                                        <BannerPromo display={true}/>
                                        <Route path="/landing-f2" exact component={Newlanding} />
                                    </div>
                                </Route> */}
                                <Route path="/gracias">
                                    <div className="page-newlanding">
                                        <Route path="/gracias" exact component={NewGracias} />
                                    </div>
                                </Route>
                                <Route path="/landing-f2">
                                    <div className="page-newlanding">
                                        {/* <BannerPromo display={true}/> */}
                                        <Route path="/landing-f2" exact component={LandingAudi} />
                                    </div>
                                </Route>
                                <Route path="/landing">
                                    {/* <BannerPromo display={true} action={".formularioValidador"}/> */}
                                    <Route path="/landing" exact component={Landing} />
                                </Route>
                                <Route path="/">
                                    <FrontendHeader />
                                    <Route path="/" exact component={Inicio} />
                                    <Route path="/departamentos" exact component={Departamentos} />
                                    <Route path="/multimedia" exact component={Multimedia} />
                                    <Route path="/promociones" exact component={Promociones} />
                                    <Route path="/promociones/:id" exact component={Promotion} />
                                    <Route path="/respaldo" exact component={Respaldo} />
                                    <Route path="/referidos" exact component={Referidos} />
                                    <Route path="/avance-de-obra" exact component={AvanceObra} />
                                    <Route path="/recorrido-virtual" exact component={RecorridoVirtual} />
                                    <Route path="/areas-comunes" exact component={AreasComunes} />
                                    <Route path="/libro-de-reclamaciones" exact component={ComplaintsBook} />
                                    <Route path="/recorrido-virtual-3D" exact>
                                        <ContactRecorrido></ContactRecorrido>
                                    </Route>
                                    <EndFooter/>
                                </Route>
                            </Switch>
                        </Suspense>
                </ScrollToTop>
            </Router>
        )
    }
}