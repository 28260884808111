import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-photoswipe/dist/photoswipe.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/simpleLightbox.scss'
import './index.scss';
import Routes from 'Routes';
import * as serviceWorker from './serviceWorker';
import $ from "jquery"

window.$ = $
window.jQuery = window.$

ReactDOM.render(
    <Routes />, document.getElementById('root')
);

if(window.location.search){
    localStorage.setItem('url_query', window.location.search)
} else {
    localStorage.setItem('url_query', "?utm_source=Orgánico")
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();

$('a').click(function (e) {
    window.ga('send', 'event', 'link_clics', 'click', $(this).attr('href'), 0);
})